import React from 'react'
import moment from 'moment';
import { futureEvent } from '../../utils/prices/getData.types'
import { Card } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

const FutureEventList = ({ events }: { events: futureEvent[] | null }) => {
    const NavigateHook = useNavigate();
    return <div className='futureEventList'>
        {
            events !== null && events.length > 0 && events.map((event: futureEvent, i) => {
                const appBegin: string = (moment(event.appBegin)).format("DD. MM.");
                const appEnd: string = (moment(event.appEnd)).format("DD. MM.");

                return <Card
                    key={i}
                    onClick={() => NavigateHook(`/${event.slug}`)}
                    style={{
                        'cursor': 'pointer'
                    }}
                    image='/images/avatar/large/elliot.jpg'
                    header={event.title}
                    meta={event.registration ? `Registrace do ${appEnd}` : `Registrace bude možná od ${appBegin}`}
                />
            })
        }
    </div>
}

export default FutureEventList;