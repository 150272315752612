import React, { useContext } from 'react'
import { Button } from 'semantic-ui-react'
import AppContext from '../../utils/context/context';
import { EActions } from '../../utils/context/types';
import TResultsProps from './result.types'

const ResultsPage = ({ message }: TResultsProps) => {
    const {dispatch} = useContext(AppContext);
    return (
        <div className={`messagediv ${message.type}`} id='response'>
            <div
                className='header-text'
                dangerouslySetInnerHTML={{ __html: message.html }}
            />
            <Button
                floated='right'
                onClick={e => {
                    // empty setMessage.payload is setting null
                    dispatch({
                        type: EActions.setMessage
                    })
                }}
            >
                Zpět k přihlášce
            </Button>
        </div>
    )
}


export default ResultsPage