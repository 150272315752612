import { Reducer } from "react";
import { initialState } from "./context";
import { EActions, TAction, Tstate } from "./types";

// reducer executes actions against the store
const reducerFunction: Reducer<Tstate, TAction> = (prevState, action) => {
    /**
     * Create new state using actions and previous state
     * We must return a new object, so we spread the previous state to the new one
     */
    let newState = {
        ...prevState
    };

    switch(action.type){
        case EActions.setMessage:
            newState['message'] = typeof action.payload !== 'undefined' ? action.payload : null;
            break;
        case EActions.initialValue:
            newState = initialState;
            break;
        case EActions.setEnableSubmit:
            newState['enableSubmit'] = action.payload;
            break;
    }

    console.log(newState);
    return newState;
}

export default reducerFunction