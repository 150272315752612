import { useReducer } from "react"
import AppContext, { initialState } from "./context"
import reducerFunction from "./reducer"
import { ProviderProps } from "./types"

const StoreProvider = ({children}: ProviderProps): JSX.Element => {
    const [currentState, dispatch] = useReducer(reducerFunction, initialState);
    
    return <AppContext.Provider value={{
        ...currentState,
        dispatch
    }}>{children}</AppContext.Provider>;
}

export default StoreProvider;