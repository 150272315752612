import { receivedData } from "renette-api/dist/types"
import API from "../initapi"
import { formConfig, futureEventInData } from "./getData.types"

export const getFutureEventsList = async (): Promise<receivedData<futureEventInData>> => {
    return API.post({
        resource: 'get',
        action: 'getEvents'
    });
}

export const getFormConfig = async (eventID: number): Promise<receivedData<formConfig>> => {
    return API.post({
        resource: "get",
        action: "getConfig",
        data: {
            eventID
        }
    });
}

export const getIsAlreadyRegistered = async (eventID: number, name: string, sname: string): Promise<receivedData<{ isAlreadyRegistered: number }>> => {
    return API.post({
        resource: "get",
        action: "isAlreadyRegistered",
        data: {
            eventID,
            name,
            sname
        }
    });
}

export const getPrice = async (note_food: string, group: string, donation: number, arrival: number, departure: number, specials: number[]): Promise<receivedData<string>> => {
    return API.post({
        resource: "apply",
        action: "getPrice",
        data: {
            note_food,
            group,
            donation,
            arrival,
            departure,
            specials
        }
    })
}