import React, { FormEvent, useContext, useEffect, useState, useTransition } from "react"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Form, Button, Divider, Grid, GridRow, GridColumn, Dropdown, Checkbox, CheckboxProps, FormGroup, Radio } from "semantic-ui-react"
import config from "../../utils/prices/config";

import { TEData, TEvent } from "../../pages/form/form.types";
import { formConfig, futureEvent } from "../../utils/prices/getData.types";
import { getIsAlreadyRegistered } from "../../utils/prices/getData";
import AppContext from "../../utils/context/context";
import { EActions } from "../../utils/context/types";

const czechChars = 'ďěščťřžýáíéúůňó';

const czechCharsCap = 'ĎŠČŤŘŽÚŇÓ';

const RegistrationForm = ({
    formConfig,
    event,
    submitHandler
}: {
    formConfig: formConfig,
    event: futureEvent,
    submitHandler: SubmitHandler<FieldValues>
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch,
        trigger,
    } = useForm({
        defaultValues: {
            eventID: event.eventID,
            name: '',
            sname: '',
            byear: '',
            email: '',
            phone: '',
            town: '',
            music_instrument: '',
            firsttime: '',
            firsttime_note: '',
            note_health: '',
            note_food: '',
            vegeta: false,
            bezlepka: false,
            note: '',
            gdpr: false,
            group: formConfig.groups[0].value,
            arrival: formConfig.arrivals[0].value,
            arrival_food: config.data.fields.arrival_food.options[config.data.fields.arrival_food.options.length - 1].value,
            departure: formConfig.departures[0].value,
            departure_food: config.data.fields.departure_food.options[0].value,
            specials: []
        }
    });

    const [onlyPartTime, setOnlyPartTime] = useState(false);

    const handleChange = (e: TEvent, data: TEData): void => {
        const { name, value, checked } = data;

        // @ts-ignore
        setValue(name, value ? value : checked);
        // data validations
        trigger(name);
    }

    const { enableSubmit, dispatch } = useContext(AppContext);
    console.log(enableSubmit);

    useEffect(() => {
        register('eventID' as never);
        register(`name` as never, {
            required: true,
            pattern: new RegExp(`^([A-Z${czechCharsCap}][a-z${czechChars}]+ ?)+`)
        });
        register(`sname` as never, {
            required: true,
            pattern: new RegExp(`^([A-Z${czechCharsCap}][a-z${czechChars}]+ ?)+`)
        });
        register(`byear` as never, {
            required: true,
            pattern: /^\d{2}$/,
            min: 14,
            max: 99,
        });
        register(`email` as never, {
            required: true,
            pattern: /^([\w_.+-])+@(([\w-])+\.)+([a-zA-Z0-9]{2,8})+$/
        });
        register(`phone` as never, {
            required: true,
            pattern: /^(\+420)?( ?[\d]{3}){3}$/
        });
        register(`town` as never, {
            required: true,
            pattern: new RegExp(`^([A-Z${czechCharsCap}][a-z${czechChars}]+)\\s?([\\w${czechCharsCap + czechChars}]+\\s?)*$`) //
        });
        register(`music_instrument` as never, {
            maxLength: 64
        });
        register(`firsttime` as never);
        register(`firsttime_note` as never, {
            maxLength: 64
        });
        register(`note_health` as never, {
            maxLength: 64
        });
        register(`note_food` as never, {
            maxLength: 42
        });
        register(`bezlepka` as never)
        register(`vegeta` as never)
        register(`note` as never, {
            maxLength: 255
        });
        register(`utery` as never, {
        });
        register(`gdpr` as never, {
            required: true
        });
        register(`group` as never, {
            required: true,
            min: 0
        });
        register(`donation` as never, {
            min: 0
        });
        register(`arrival` as never, {
            required: true,
            min: 0,
            validate: value => Boolean(onlyPartTime || value)
        });
        register(`departure` as never, {
            required: true,
            min: 0,
            validate: value => Boolean(onlyPartTime || value)
        });
        register(`arrival_food` as never, {
            required: true,
            min: 0,
            validate: value => Boolean(onlyPartTime || value)
        });
        register(`departure_food` as never, {
            required: true,
            min: 0,
            validate: value => Boolean(onlyPartTime || value)
        });
        register(`specials` as never);
    }, []);

    const [prepared, startTransition] = useTransition();
    function standardFrom() {
        if (formConfig?.arrivals?.length) {
            return formConfig.arrivals[0].text;
        }
        return '';
    }
    function standardTo() {
        if (formConfig?.departures?.length) {
            return formConfig.departures[0].text;
        }
        return '';
    }

    const checkRegistered = () => {
        if (typeof errors.name !== 'undefined' || typeof errors.sname !== 'undefined') {
            return;
        }
        const name = getValues(['name', 'sname']);
        if (name[0].length < 3 || name[1].length < 3) {
            return;
        }

        startTransition(() => {
            getIsAlreadyRegistered(event.eventID, name[0], name[1])
                .then(res => {
                    console.log('isAlreadyRegistered', !res.data.isAlreadyRegistered);
                    dispatch({
                        action: EActions.setEnableSubmit,
                        payload: !Boolean(res.data.isAlreadyRegistered)
                    });
                });
        });
    }

    return (<Form>
        <Form.Group>
            <Form.Input
                name="name"
                width={6}
                fluid
                label="Jméno"
                placeholder="Pepa"
                error={errors.name ? "Používej české znaky, první písmeno jména by mělo být velké. Více jmen odděluj mezerou" : null}
                onChange={handleChange}
                onBlur={checkRegistered}
            />

            <Form.Input
                name="sname"
                width={7}
                fluid
                label="Příjmení"
                placeholder="Josef"
                error={errors.sname ? "Používej české znaky, první písmeno jména by mělo být velké. Více jmen odděluj mezerou" : null}
                onChange={handleChange}
                onBlur={checkRegistered}
            />
            <Form.Input
                type="number"
                name="byear"
                width={3}
                placeholder="42"
                label="Věk"
                error={errors.byear ? "Zadej svůj aktuální věk. Měl bys mít aspoň 14 let, s ohledem na poznámku o \"dospělosti\" výše :)" : null}
                onChange={handleChange}
            />
        </Form.Group>
        <Form.Group>
            <Form.Input
                type="email"
                name="email"
                width={10}
                placeholder="pepa.josef@email.cz"
                label="📧 E-mail"
                error={errors.email ? "Zadej prosím platnou e-mailovou adresu" : null}
                onChange={handleChange}
            />
            <Form.Input
                name="phone"
                width={6}
                label="Telefon"
                error={errors.phone ? "Zadej prosím české telefonní číslo" : null}
                onChange={handleChange}
            />
        </Form.Group>
        <Form.Group>
            <Form.Input
                name="town"
                width={10}
                label="🏠 Tvoje obec?"
                error={errors.town ? "Zadej své město. První písmeno je velké" : null}
                onChange={handleChange}
            />
            <Form.Select
                name="firsttime"
                width={6}
                label="Jedeš na Travnou poprvé?"
                error={errors.firsttime ? "Prosím, vyber možnost" : null}
                options={[
                    { key: "ano", value: 1, text: "Ano" },
                    { key: "ne", value: 0, text: "Ne" }
                ]}
                onChange={handleChange}
            />
        </Form.Group>
        {
            watch('firsttime') ?
                <Form.Input
                    name="firsttime_note"
                    label="Kdo Tě na Travnou pozval nebo jak ses o ní dozvěděl?"
                    onChange={handleChange}
                /> : null
        }
        <Form.Group>
            <Form.TextArea
                name="note_health"
                width={9}
                label="Zdravotní omezení/potřeby"
                error={errors.note_health ? "Text může být max. 64 znaků dlouhý, buďte struční, prosím" : null}
                onChange={handleChange}
            />
            <Form.TextArea
                name="note"
                width={9}
                label="Poznámka"
                error={errors.note ? "Text může být max. 255 znaků dlouhý, buďte struční, prosím" : null}
                onChange={handleChange}
            />
        </Form.Group>
        <h3>Stravovací omezení:</h3>
        <Form.Checkbox
            name="bezlepka"
            label="Bezlepková dieta"
            onChange={handleChange}
        />
        <Form.Checkbox
            name="vegeta"
            label="Jsem vegetarián"
            onChange={handleChange}
        />
        <Form.Input
            name="note_food"
            label="Jiná stravovací omezení"
            error={errors.note_food ? "Nerozepisuj se a napiš nejvýše 42 znaků :)" : null}
            onChange={handleChange}
        />

        <Divider />
        <Form.Input
            name="music_instrument"
            label="🎺 Přijedu v doprovodu těchto nástrojů"
            error={errors.music_instrument ? "Maximum je 64 znaků" : null}
            onChange={handleChange}
        />
        <Form.Checkbox
            name="gdpr"
            label="Souhlasím se zpracováním osobních údajů, zasíláním informací o dalších akcích a s tím, že pověřené osoby mohou pořizovat obrazové snímky a obrazové a zvukové záznamy mé osoby a zároveň, že Setkávání Travná, z.s. může tyto obrazové snímky a obrazové a zvukové záznamy použít ke své propagaci."
            error={errors.gdpr ? "Je potřeba souhlasit s podmínkami" : null}
            onChange={handleChange}
        />

        <Divider />

        <Grid>
            <GridRow>
                <GridColumn width={16}>
                    <FormGroup>
                        <Form.Select
                            width={12}
                            name="group"
                            label="Typ účastníka"
                            error={errors.group ? "Prosím, vyber možnost" : null}
                            options={formConfig.groups}
                            onChange={handleChange}
                            defaultValue={formConfig.groups[0].value}
                        />
                        <Form.Input
                            width={6}
                            name="donation"
                            type="number"
                            label="Chci přispět na organizaci akce částkou"
                            min="0"
                            defaultValue="0"
                            onChange={handleChange}
                        />
                    </FormGroup>
                </GridColumn>
            </GridRow>

            <Divider />

            <GridRow>
                <GridColumn width={16}>
                    <h3>🚆 Příjezd a odjezd</h3>
                    <p>
                        Dej nám prosím vědět, kdy na akci dorazíš, jak dlouho se zdržíš a co budeš jíst. Zejména kuchyně to velmi ocení. :)
                    </p>
                    <GridRow>
                        <Radio
                            label={`Zúčastním se standardní části akce (${standardFrom()} - ${standardTo()})`}
                            style={{
                                fontWeight: 'bold'
                            }}
                            onChange={(e: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                                setOnlyPartTime(!data.checked ?? false)
                            }}
                            checked={!onlyPartTime}
                        />
                    </GridRow>
                    <GridRow>
                        <Radio
                            label={`Zúčastním se jiné části akce`}
                            style={{
                                fontWeight: 'bold'
                            }}
                            onChange={(e: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                                setOnlyPartTime(data.checked ?? false)
                            }}
                            checked={onlyPartTime}
                        />
                    </GridRow>
                </GridColumn>
            </GridRow>
            {
                onlyPartTime ? <>
                    <small>(Konkrétní možnosti prodloužení či zkrácení akce nemusí být pro každou akci k dispozici.)</small>
                    <GridRow>
                        <GridColumn width={8}>
                            <Form.Select
                                name="arrival"
                                label="Přijedu"
                                error={errors.arrival ? "Prosím, vyber možnost" : null}
                                options={formConfig.arrivals}
                                onChange={handleChange}
                                defaultValue={formConfig.arrivals[0].value}
                            />
                            <Form.Select
                                name="arrival_food"
                                label="První jídlo"
                                error={errors.arrival_food ? "Prosím, vyber možnost" : null}
                                options={config.data.fields.arrival_food.options}
                                onChange={handleChange}
                                defaultValue={config.data.fields.arrival_food.options[config.data.fields.arrival_food.options.length - 1].value}
                            />
                        </GridColumn>
                        <GridColumn width={8}>
                            <Form.Select
                                name="departure"
                                label="Odjedu"
                                error={errors.departure ? "Prosím, vyber možnost" : null}
                                options={formConfig.departures}
                                onChange={handleChange}
                                defaultValue={formConfig.departures[0].value}
                            />
                            <Form.Select
                                name="departure_food"
                                label="Poslední jídlo"
                                error={errors.departure_food ? "Prosím, vyber možnost" : null}
                                options={config.data.fields.departure_food.options}
                                onChange={handleChange}
                                defaultValue={config.data.fields.departure_food.options[0].value}
                            />
                        </GridColumn>
                    </GridRow>
                </> : null
            }
            <GridRow>
                <GridColumn width={16}>
                    <h3>Prádlo</h3>
                    <p>
                        Prostěradlo je automaticky v ceně, abychom trochu uchránili matrace před znečištěním. Pokud si k tomu chcete půjčit něco dalšího, stačí si jen vybrat :)
                    </p>
                    <Dropdown
                        name="specials"
                        label="Chci si půjčit prádlo;"
                        onChange={handleChange}
                        options={formConfig.items}
                        fluid
                        multiple
                        selection
                    />
                </GridColumn>
            </GridRow>
        </Grid>

        <Divider />

        <div className="confirm-panel">
            <Button
                type="submit"
                color="olive"
                onClick={handleSubmit(submitHandler)}
                disabled={!enableSubmit}
            >
                Přihlásit se
            </Button>
        </div>

    </Form>
    );
}

export default RegistrationForm