import { createContext } from "react";
import { TContextType, Tstate } from "./types";

export const initialState: Tstate = {
    message: null,
    enableSubmit: true,
    priceString: '' 
};

export const initialContext: TContextType = {
    message: null,
    enableSubmit: true,
    dispatch: () => {},
    priceString: '0Kč'
};

const AppContext = createContext<TContextType>(initialContext);

export default AppContext;