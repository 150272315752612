import { e_group, e_items, t_data_raw } from "./types";

const config: t_data_raw = {
    title: "Letní Travná 2022",
    begin: "2022-07-14",
    end: "2022-07-22",
    data: {
        fields: {
            arrival: {
                options: [
                    {key: "sat", value: 20220709, text: "V Sobotu (9. 7.)"},
                    {key: "sun", value: 20220710, text: "V Neděli (10. 7.)"},
                    {key: "mon", value: 20220711, text: "V Pondělí (11. 7.)"}
                ]
            },
            arrival_food: {
                options: [
                    {key: "luch", value: 2, text: "Oběd"},
                    {key: "dier", value: 3, text: "Večeře"}
                ]
            },
            departure: {
                options: [
                    {key: "thu", value: 20220714, text: "Ve čtvrtek (14. 7.)"},
                    {key: "fri", value: 20220715, text: "V pátek (15. 7.)"},
                    {key: "sat", value: 20220716, text: "V sobotu (16. 7.)"}
                ]
            },
            departure_food: {
                options: [
                    {key: "breq", value: 1, text: "Snídaně"},
                    {key: "luch", value: 2, text: "Oběd"}
                ]
            }
        },
        prices: {
            night: 333,
            items: [
                {
                    text: "Prostěradlo",
                    value: e_items.prosteradlo,
                    price: 25
                },
                {
                    text: "Ručník",
                    value: e_items.rucnik,
                    price: 11
                },
                {
                    text: "Osuška",
                    value: e_items.osuska,
                    price: 13
                },
                {
                    text: "Peřina",
                    value: e_items.perina,
                    price: 25
                },
                {
                    text: "Polštář",
                    value: e_items.polstar,
                    price: 12
                }
            ]

        },
        groups: [
            {
                text: "Účastník",
                key: "ucastnik",
                value: e_group.ucastnik
            },
            {
                text: "První přihlašovaný sourozenec",
                key: "st",
                value: e_group.st
            },
            {
                text: "Druhý až n-tý sourozenec",
                key: "nth",
                value: e_group.nth
            },
            {
                text: "Člen organizačního týmu",
                key: "orgtym",
                value: e_group.orgtym
            },
            {
                text: "Prosím o podporu ze sociálního fondu G62",
                key: "g62",
                value: e_group.g62
            }
        ]
    },
    exception: [
        {
            until: "2022-06-14",
            data: {
                prices: {
                    night: 170
                }
            }
        },
        {
            field: "group",
            value: e_group.orgtym,
            data: {
                prices: {
                    night: 170
                }
            },
            until: "2022-06-14"
        },
        {
            field: "group",
            value: e_group.nth,
            data: {
                prices: {
                    night: 170
                }
            },
            until: "2022-06-14"
        }
    ]
}

export default config