import React, { useContext, useEffect, useState } from 'react';

import PageForm from './pages/form/form.page';

import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { futureEvent } from './utils/prices/getData.types';
import { getFutureEventsList } from './utils/prices/getData';
import PageEvents from './pages/events/events.page';
import AppContext from './utils/context/context';
import API from './utils/initapi';
import { EActions } from './utils/context/types';

const App = () => {
  const [events, setEvents] = useState<futureEvent[] | undefined>(undefined);
  const {dispatch} = useContext(AppContext);

  useEffect(() => {
    if (events === undefined) {
      // no data yet, so load it
      getFutureEventsList()
      .then(res => {
        setEvents(res.data?.events)
      });
    }

    API.setActionHandler('message', (message) => {
      dispatch({
        type: EActions.setMessage,
        payload: {
          html: message.data.html
        }
      });
      return message;
    });
  }, [dispatch, events]);

  if (typeof events === 'undefined' || events.length === 0){
    // dont render if no events loaded
    return null;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={<PageEvents futureEvents={events} />}
        />
        <Route
          path='/:event'
          element={<PageForm futureEvents={events} />}
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App;