export enum e_group {
    ucastnik = 1,
    st,
    nth,
    orgtym,
    g62
}

export enum e_items{
    prosteradlo = 1,
    rucnik,
    osuska,
    perina,
    polstar
}

export type t_data_groups_raw = {
    text: string,
    key: string,
    value: e_group
}

export type t_data_price_item_raw = {
    text: string,
    value: e_items
    price: number
}

export type t_data_item_prices_raw = {
    night: number,
    items: t_data_price_item_raw[]
}

export type t_data_option_raw = {
    key: string,
    value: number,
    text: string
}

export type exception_data = {
    prices: {
        night: number,
        items?: t_data_price_item_raw[]
    }
}

export type t_data_exception_raw = {
    until?: string,
    since?: string,
    field?: string,
    value?: number | string,
    data: exception_data
}

export type t_data_raw = {
    title: string,
    begin: string,
    end: string,
    exception: t_data_exception_raw[]
    data: {
        fields: {
            [k: string]: {
                options: t_data_option_raw[]
            }
        },
        prices: t_data_item_prices_raw,
        groups: t_data_groups_raw[]
    }
}