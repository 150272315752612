import React from 'react';
import { Navigate } from 'react-router';
import FutureEventList from '../../components/futureEvents/futureEvents.comp';
import { futureEvent } from '../../utils/prices/getData.types';

const PageEvents = ({ futureEvents }: { futureEvents: futureEvent[] | null }) => {
  if (futureEvents ===  null){
      return null;
  }
  return futureEvents.length === 1 ? <Navigate to={`/${futureEvents[0].slug}`} /> : <FutureEventList events={futureEvents} />;
}

export default PageEvents;