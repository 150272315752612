import { sentMessage } from "renette-api/dist/types";
import { futureEvent } from "../prices/getData.types";

// stored data
export interface Tstate {
    message: sentMessage | null,
    enableSubmit: boolean,
    priceString: string,
    eventList?: futureEvent[],
};

// type for the context
export interface TContextType extends Tstate {
    dispatch: CallableFunction,
};

export type ProviderProps = {
    children: React.ReactNode,
}

// action types
export enum EActions {
    initialValue,
    setEventsData,
    setMessage,
    clearMessage,
    setEnableSubmit,
    setPriceString,
};

// dispatch action object
export type TAction = {
    type: EActions,
    payload?: any,
};