import React, { useContext, useEffect, useState } from "react"
import { FieldValues, SubmitHandler } from "react-hook-form";
import { useParams } from "react-router";
import { Container, Dimmer, Loader } from "semantic-ui-react";
import RegistrationForm from "../../components/form/Form";
import AppContext from "../../utils/context/context";
import API from "../../utils/initapi";
import { getFormConfig } from "../../utils/prices/getData";

import { formConfig, futureEvent } from '../../utils/prices/getData.types'
import ResultsPage from "../result/results.page";
import { TResult } from "./form.types";

const Result = (
    {
        currentEvent,
        response,
        formConfig,
        onSubmitAction
    }: TResult) => {
    if (response !== null && response.html.length > 0){
        // we got message
        return <ResultsPage message={response}/>;
    }

    if (formConfig !== null && formConfig && currentEvent !== undefined){
        // render form
        return <>
            <div className="header-text">
                <h2>{currentEvent.title}</h2>
                <div className="description" dangerouslySetInnerHTML={{ __html: currentEvent.desc }} />
            </div>
            <RegistrationForm formConfig={formConfig} event={currentEvent} submitHandler={onSubmitAction} />
        </>;
    } else {
        return null;
    }
}

const PageForm = ({ futureEvents }: { futureEvents: futureEvent[]}) => {
    const { event } = useParams();
    const [ currentEvent, setCurrentEvent ] = useState<futureEvent>();
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ formConfig, setFormConfig ] = useState<formConfig | null>(null)

    const {message} = useContext(AppContext);

    const onSubmitAction: SubmitHandler<FieldValues> = (data) => {
        setLoading(true)

        // trim whitespaces from all fields before sending
        for (let i in data){
            if (typeof data[i]  !== 'string'){
                continue;
            }

            // trim whitespaces
            data[i] = data[i].trim();
        }

        data['note_food'] = (data['bezlepka'] ? 'bezlepková,' : '') + (data['vegeta'] ? 'vegetarian,' : '') + data['note_food'];

        delete data['bezlepka'];
        delete data['vegeta'];

        try {
            API.post({
                resource: "apply",
                action: "new",
                data: data
            })
                .catch((data) => {
                    console.log(data)
                })
                .finally(() => setLoading(false))
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        // select current event if not selected
        if (!currentEvent){
            const filteredEvents = futureEvents.filter((item) => item.slug.normalize() === event?.normalize())
            document.title = `${filteredEvents[0].title} - Registrace`;
            setCurrentEvent(filteredEvents[0]);
        }
    //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(currentEvent?.eventID != null)
        {
            getFormConfig(currentEvent?.eventID)
            .then(res => {
                setLoading(false);
                setFormConfig(res.data)
            })
        }
    }, [currentEvent]);
    
    return <Container className="content">
        {
            loading ?
                <Dimmer active>
                    <Loader />
                </Dimmer>
                : null
        }
        <Result
            currentEvent={currentEvent}
            formConfig={formConfig}
            response={message}
            onSubmitAction={onSubmitAction}
        />
    </Container>
};

export default PageForm;
